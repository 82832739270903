import React, { Component } from "react"
import Formsy from 'formsy-react';
import { connect } from "react-redux"

import Layout from "../components/layout"
import Wizard from "../components/wizard"
import vehicalIcon from "../images/gray/1.svg"
import bottomLine from "../images/line.png"
import MyInputMask from "../components/MyInputMask";
import MyAuto from "../components/MyAuto";
import Popup from "../components/popup";
import quest from "../images/questions-icon.svg"

import { isAuthenticated,getAuthenticatedUser } from '../core/util';

import MyCheckbox from "../components/MyCheckbox";
import { navigate } from "gatsby"
import SEO from "../components/seo"
import ButtonLoader from "../components/buttonLoader";
import * as actionCreators from './../store/actions';
import { formatQueryParams } from "../core/util";
import closeChat from '../images/close-icon.svg';
import infoicon from '../images/vehical-info.svg';
import { zonar } from './../components/constant'

class VehicleInfoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      showalert:false,
      isValueChangeError: false,
      selectedYear: "",
      selectedMake: "",
      selectedModel:"",
      selectedDealer:"",
      enterVin:null,
      enterMileage:null,
      enterzone:"",
      plans:null,
      infoError:null,
      check:{vin:false, mile:false, zone:false,plan:true},
      vehicleFeature : {
        isAWD4x4 : false,
        isDiesel : false,
        isHybrid : false,
        isElectric : false,
        isTurboSuper :false,
        isOneTon :false,
        isRideshare:false,
        isCommercial:false,
        isEnhanchedTech:false,
        isCanadian:false,
        isWPW:false,
        isLiftKit:false
      },
      yearList: ['Loading...'],
      makeList: ['Loading...'],
      modelList: ['Loading...'],
      dealerList: [],
    };
  }

  componentDidMount = async () => {
    
    const { showLoader, hideLoader, step1Data } = this.props;
    showLoader();
    
    await this.getYear();
    await this.getMakes();
    await this.getDealers();

    
    const param = formatQueryParams(this.props.location.search);
    if (param && param.r) {
      
    }
    else if(step1Data.vehicleInfo){
        if(step1Data.vehicleInfo.maker!==""){
          await this.getModels(step1Data.vehicleInfo.maker);
        }
        
         
        this.setState({
          canSubmit:true,
          enterVin : step1Data.vehicleInfo.vin,
          enterMileage:step1Data.vehicleInfo.mileage,
          enterzone:step1Data.vehicleInfo.zone,
          selectedYear : step1Data.vehicleInfo.year,
          selectedMake : step1Data.vehicleInfo.maker,
          selectedModel : step1Data.vehicleInfo.model,
          vehicleFeature : step1Data.vehicleFeature,
          check:{vin:step1Data.vehicleInfo.vin?true:false, mile:true, zone:true,plan:true}
        });

        if(step1Data.vehicleInfo.vin){
          this.setState({
            yearList: [step1Data.vehicleInfo.year],
            makeList: [step1Data.vehicleInfo.maker],
            modelList: [step1Data.vehicleInfo.model]
          })
        }
        
        
           
    } else {
      
      
      if (param && param.q) {
        await this.getFromDirect(param.q);
      }
      if(this.props.location.state && this.props.location.state.zone){
        this.setState({enterzone:this.props.location.state.zone});
        this.state.check.zone = true;
      }
      
    }
    
    hideLoader();
    /* if(window.gaconnector){
      console.log(window.gaconnector.getCookieValues())
    } */
  }

  getYear = async() => {
    let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getYears'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
    await fetch(uri)
    .then(res => res.json())
    .then((res) => {
      this.setState({yearList:res});
    });
  }

  getDealers = async() => {
    if(global.dealertoken && global.dealertoken==process.env.peak_dealer){
      let dealId = "";
      if (isAuthenticated()) {
        let usrdata = getAuthenticatedUser();
        if(usrdata.dealerId){
          dealId = usrdata.dealerId;
        } 
        if(usrdata.dealerId || usrdata.dealAdmin){
          let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getDealers/?dealertoken='+global.dealertoken;
          await fetch(uri)
          .then(res => res.json())
          .then((res) => {
            if(!dealId) dealId =  res[0];
            this.setState({dealerList:res,selectedDealer:dealId});
          });
        }
      }
    }
  }

  getMakes = async() => {
    let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getMakes'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
    await fetch(uri)
    .then(res => res.json())
    .then((res) => {
      this.setState({makeList:res});
    });
  }

  getModels = async(make) => {
    let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getModels'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
    await fetch(uri,{
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
          make: make,
      })
    })
    .then(res => res.json())
    .then((res) => {
      this.setState({modelList:res});
    });
  }

  getFromDirect = async(paramId) => {
    
    const { showLoader, hideLoader, saveSecondStep, saveFirstStep, saveThirdStep, saveFourthStep, saveSecondStepResponse, saveThirdStepResponse } = this.props;
    showLoader();
    let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getPlanById'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                _id: paramId,
            })
          })
        .then(res => res.json())
        .then((res) => {
          if(res.status == 1){
            let secondstepResponse = {
                userId: res.data.step3.userId,
                vehicleId: res.data.step3.vehicleId,
                recordId: res.data.step3.recordId
            }
            saveFirstStep(res.data.step1);
            saveSecondStep(res.data.step2);
            saveSecondStepResponse(secondstepResponse);
            saveThirdStep(res.data.step3);
            saveThirdStepResponse(res.data.step3);
            saveFourthStep(res.data.step4)
            let step1Data = res.data.step1;
            if(step1Data.vehicleInfo){
              this.setState({
                enterVin : step1Data.vehicleInfo.vin,
                enterMileage:step1Data.vehicleInfo.mileage.toString(),
                enterzone:step1Data.vehicleInfo.zone,
                selectedYear : step1Data.vehicleInfo.year,
                selectedMake : step1Data.vehicleInfo.maker,
                selectedModel : step1Data.vehicleInfo.model,
                vehicleFeature : step1Data.vehicleFeature,
                plans : res.data.plans,
                check:{vin:true, mile:true, zone:true,plan:true}
              });
            }
            sessionStorage['plans'+(global.dealertoken?global.dealertoken:'')] = JSON.stringify(res.data.plans);
            this.state.canSubmit = true;
          } else {
            navigate("/vehicleInfo/?q="+paramId+(global.dealertoken?'&dealertoken='+global.dealertoken:''))
          }
          hideLoader();
        });
  }

  selectionChange = async(value, type) => {}

  valueChanges = async(form, self, value) => {//mileage
    
    this.state.enterMileage = value;
    var numValue = parseInt(value)*1;
    
    if (numValue > 200000) {
      if (!this.state.isValueChangeError) {
        this.setState({ isValueChangeError: true }, () => {
          self.refs[`${form}`].updateInputsWithError({
            'vehicleInfo.mileage': 'Sorry, mileage cannot exceed 200,000 miles.'
          });
          this.setState({ isValueChangeError: false })
        })
      }
    } 
    
   
    if(numValue>=0 && numValue <= 200000){
      this.state.check.mile = true;
    } else {
        this.state.check.mile = false;
    }
    this.validateForm();

  }
 
  featureChanges = (ele, mythis, value) => {
    let vehicleFeature = this.state.vehicleFeature;
    
    vehicleFeature[ele] = value;

    if(ele==='isRideshare' && value){
      vehicleFeature.isCommercial = false;
    } else if(ele==='isCommercial' && value){
      vehicleFeature.isRideshare = false;
    }
    
    if(ele==='isDiesel' && value) {
      vehicleFeature.isHybrid = false;
      vehicleFeature.isElectric = false;
    } else if(ele==='isHybrid' && value) {
      vehicleFeature.isDiesel = false;
      vehicleFeature.isElectric = false;
    } else if(ele==='isElectric' && value) {
      vehicleFeature.isHybrid = false;
      vehicleFeature.isDiesel = false;
    }
    
    this.setState({ vehicleFeature: vehicleFeature })
    
  }
  async vinChanges(form, self, value) {
  
    this.setState({enterVin:value});
    if(value.length === 0){
      await this.getYear();
      await this.getMakes();
    }
    if(value.length===17){
      
      let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getvindet?vinno='+value+(global.dealertoken?'&dealertoken='+global.dealertoken:'');
      await fetch(uri)
      .then(res => res.json())
      .then((result) => { 
          if(result.Year){
            let vehicleFeature = {
              isRideshare : this.state.vehicleFeature.isRideshare,
              isCommercial : this.state.vehicleFeature.isCommercial,
              isEnhanchedTech : this.state.vehicleFeature.isEnhanchedTech,
              isCanadian : this.state.vehicleFeature.isCanadian,
              isWPW : this.state.vehicleFeature.isWPW,
              isLiftKit : this.state.vehicleFeature.isLiftKit,
              isAWD4x4 : result.DriveType==="FourByFour" || result.DriveType==="AWD" ? true : false,
              isDiesel : result.FuelType==="Diesel" ? true : false,
              isHybrid : result.FuelType==="Hybrid" ? true : false,
              isElectric : result.FuelType==="Electric" ? true : false,
              isTurboSuper : result.FuelDelivery==="Turbo" || result.FuelDelivery==="Supercharged" ? true : false,
            }
            this.setState({
              yearList: [result.Year],
              makeList: [result.Make],
              modelList: [result.Model],
              selectedYear : result.Year,
              selectedMake : result.Make,
              selectedModel : result.Model,
              vehicleFeature : vehicleFeature
            });
            this.state.check.vin = true;
            //console.log(this.state)
          } else {
            if (!this.state.isValueChangeError) {
              //'vehicalInfoForm', this, e)
              this.setState({ isValueChangeError: true }, () => {
                self.refs[`${form}`].updateInputsWithError({
                  'vehicleInfo.vin': 'Sorry, Please enter correct VIN.'
                });
                this.setState({ isValueChangeError: false })
                
              })
            }
            let vehicleFeature = {
              isRideshare : this.state.vehicleFeature.isRideshare,
              isCommercial : this.state.vehicleFeature.isCommercial,
              isEnhanchedTech : this.state.vehicleFeature.isEnhanchedTech,
              isCanadian : this.state.vehicleFeature.isCanadian,
              isWPW : this.state.vehicleFeature.isWPW,
              isLiftKit : this.state.vehicleFeature.isLiftKit,
              isAWD4x4 : false,
              isDiesel : false,
              isHybrid : false,
              isElectric : false,
              isTurboSuper : false,
              
            }
            this.setState({ vehicleFeature: vehicleFeature,selectedYear:'',selectedMake:'',selectedModel:'',modelList: ['Select Model*']})
            this.state.check.vin = false;
            this.getYear();
            this.getMakes();
            
          }
      });
      this.validateForm();
    } else {
      
      let vehicleFeature = {
        isRideshare : this.state.vehicleFeature.isRideshare,
        isCommercial : this.state.vehicleFeature.isCommercial,
        isEnhanchedTech : this.state.vehicleFeature.isEnhanchedTech,
        isCanadian : this.state.vehicleFeature.isCanadian,
        isWPW : this.state.vehicleFeature.isWPW,
        isLiftKit : this.state.vehicleFeature.isLiftKit,
        isAWD4x4 : false,
        isDiesel : false,
        isHybrid : false,
        isElectric : false,
        isTurboSuper : false,
      }
      
      
      this.setState({ vehicleFeature: vehicleFeature,selectedYear:'',selectedMake:'',selectedModel:'',modelList : ['Select Model*'] })
      this.state.check.vin = false;
      this.validateForm();
    }
  }
  validateForm = async() => {
    //console.log(this.state.selectedYear+' && '+this.state.selectedMake+' && '+this.state.selectedModel)
    if(this.state.check.mile && this.state.check.zone){
      if(this.state.check.vin) {
        this.enableButton();
      } else if(this.state.selectedYear && this.state.selectedMake && this.state.selectedModel){
        this.enableButton();
      } else {
        //console.log(this.state.selectedYear+' && '+this.state.selectedMake+' && '+this.state.selectedModel)
        this.state.infoError = "";
        this.disableButton();
      }
        
    } else {
        
        this.disableButton();
    }
  }
  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  enableButton = () => {
    this.setState({ canSubmit: true,infoError:"" });
  }
  submit = () => {
    if(this.state.canSubmit){
      if(this.state.check.vin){
        this.nextStep();
      } else {
        this.setState({ showalert: true });
      }
    }
  }
  closeAlert = () => {
    this.setState({ showalert: false });
  }
  nextStep = () => {
    const { step2response, saveFirstStep,showLoader } = this.props
    showLoader();
    //console.log(this.state.enterVin);
    let model = {
      "vehicleInfo": {
        "vin": this.state.enterVin,
        "mileage": this.state.enterMileage,
        "zone": this.state.enterzone,
        "year": this.state.selectedYear,
        "maker": this.state.selectedMake,
        "model": this.state.selectedModel,
        "dealer":this.state.selectedDealer
      },
      "vehicleFeature": {
        "isRideshare": this.state.vehicleFeature.isRideshare,
        "isCommercial": this.state.vehicleFeature.isCommercial,
        "isEnhanchedTech": this.state.vehicleFeature.isEnhanchedTech,
        "isCanadian": this.state.vehicleFeature.isCanadian,
        "isWPW": this.state.vehicleFeature.isWPW,
        "isLiftKit": this.state.vehicleFeature.isLiftKit,
        "isAWD4x4": this.state.vehicleFeature.isAWD4x4,
        "isDiesel": this.state.vehicleFeature.isDiesel,
        "isHybrid": this.state.vehicleFeature.isHybrid,
        "isElectric": this.state.vehicleFeature.isElectric,
        "isTurboSuper": this.state.vehicleFeature.isTurboSuper,
      }
    }
    
    saveFirstStep(model);

    if(global.dealertoken && global.href) this.ahrefElement.click();

    if (step2response.recordId) {
      navigate(`/yourInfo/?q=${step2response.recordId}`+(global.dealertoken?'&dealertoken='+global.dealertoken:''), {
        state: { model },
      })
    } else {
      navigate("/yourInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''), {
        state: { model },
      })
    }
  }

  sugChange = async(value,type) =>{
    console.log(value,type)
    if (type === 'make') {
      this.setState({selectedMake: value,modelList:[],selectedModel:''});
      this.state.selectedModel = '';
      /* if(value !== '') */
      await this.validateForm();
      await this.getModels(value);
    } else  if (type === 'year') {
      this.setState({ selectedYear: value });
      await this.validateForm();
    } else  if (type === 'model') {
      this.setState({ selectedModel: value });
      this.state.selectedModel = value;
      await this.validateForm();
    } else  if (type === 'zone') {
        this.setState({ enterzone: value });
        this.state.check.zone = zonar.includes(value);
        await this.validateForm();
    } else  if (type === 'dealer') {
      this.setState({ selectedDealer: value });
      this.state.selectedDealer = value;
    }
  }
  
   
  render() {
    const { yearList, makeList, modelList, canSubmit,vehicleFeature,infoError,showalert, dealerList, selectedDealer } = this.state;
   
   
    return (
      <Layout layoutType="main" >
        {global.dealertoken?
          <a className="hideme" id="price-step2" href={global.href+"#price-step2"} ref={ahref => this.ahrefElement = ahref} target="_top">trigger</a>
        :''}
        <SEO title="Vehicle Info" keywords={[`gatsby`, `application`, `react`]} />
        <div className="chat-content">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
            <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
              <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
              </g>
            </g>
          </svg>

        </div>
        {showalert?
        <div className="success-container">
            <div className="success-inner small">
                <div onClick={() => this.closeAlert()} className="close-btn close">
                    <img className="" src={closeChat} alt="" />
                </div>

                <div className="content">
                    <div className="subdiv">
                      <div className="subleft">
                      <img src={vehicalIcon} alt="Vehicle Info" />
                      </div>
                      <div className="subright">
                      We noticed you did not give us your VIN — no big deal!  We can still give you a really good idea of your payment without it, but we will need it if you want to buy a contract.
                      </div>
                    </div>
                    <div onClick={() => this.nextStep(null)} className="button">
                        <button className={global.dealertoken?'btn grayish':'btn'}>OK</button>
                    </div>
                </div>
            </div>
        </div>
        :''}
        <section className={global.dealertoken?'grayish':''}>
        
          <Wizard></Wizard>

          

          <div className="custom-container vehinfo">

            <div className="title-container">
              <div className="icon-image">
                <img src={vehicalIcon} alt="icon" />
              </div>
              <div className="title-text">
                <h1>Vehicle Info</h1>
                <p>Providing your vehicle’s year, make, model, and mileage helps us narrow down your options.</p>
              </div>
            </div>

            <div className="body-content">
              <Formsy ref="vehicalInfoForm" onValidSubmit={this.submit} >
                <div className="form-container-inline">
                  <div className="form-left">
                  {/* <div className="form-left">Don’t have VIN, no problem</div> */}
                  <div className="form-label-group error">
                      <MyInputMask
                        name="vehicleInfo.vin"
                        id="vehvin"
                        mask="*****************"
                        valueChanges={(e) => this.vinChanges('vehicalInfoForm', this, e)}
                        placeholder="VIN"
                        className="form-control"
                        validations={{maxLength: 17,minLength: 17}}
                        value={this.state.enterVin}
                        isLabelRequired={true}
                        hint="Don't have your VIN? No problem!"
                        validationError="Please enter a valid VIN."
                      />
                    </div>
                    <div className="form-label-group error">
                      <MyInputMask
                        name="vehicleInfo.mileage"
                        id="mileage"
                        mask="999999"
                        placeholder="Mileage"
                        className="form-control"
                        valueChanges={(e) => this.valueChanges('vehicalInfoForm', this, e)}
                        value={this.state.enterMileage}
                        isLabelRequired={true}
                        validations="isNumeric"
                        validationError="Please enter a valid number."
                        required
                      />
                    </div>
                    <div className="form-label-group titlecase">
                      
                      {/* <MySelect
                        name="vehicleInfo.zone"
                        value={this.state.enterzone}
                        isLabelRequired={true}
                        required={true}
                        selecteChange={(e) => this.selectionChange(e, 'zone')}
                        options={zonar}
                      /> */}
                      <label>State*</label>
                      <MyAuto 
                        value={this.state.enterzone} 
                        options={zonar}  
                        sugChange={(e) => this.sugChange(e, 'zone')}
                        name="zone"
                      />
                      
                      
                    </div>
                    {/* left end */}
                    


                  </div>
                  <div className="form-right">
                    <div className="form-label-group error">
                      <label>Year*</label>
                      <MyAuto 
                        value={this.state.selectedYear} 
                        options={yearList}  
                        sugChange={(e) => this.sugChange(e, 'year')} 
                        name="year"
                      />
                    </div>
                    <div className="form-label-group upper">
                      
                      {/* <MySelect
                        name="vehicleInfo.maker"
                        value={this.state.selectedMake}
                        selecteChange={(e) => this.selectionChange(e, 'make')}
                        options={makeList}
                      /> */}
                      <label>Make*</label>
                      <MyAuto 
                        value={this.state.selectedMake} 
                        options={makeList}  
                        sugChange={(e) => this.sugChange(e, 'make')} 
                        name="make"
                      />
                    </div>
                    

                    <div className="form-label-group upper">
                      
                      {/* <MySelect
                        name="vehicleInfo.model"
                        selecteChange={(e) => this.selectionChange(e, 'model')}
                        value={this.state.selectedModel}
                        options={modelList}
                      /> */}
                      <label>Model*</label>
                      <MyAuto 
                        value={this.state.selectedModel} 
                        options={modelList}  
                        sugChange={(e) => this.sugChange(e, 'model')} 
                        name="model"
                      />
                    </div>

                    {/* right end */}
                    
                  </div>
                </div>
                {global.dealertoken!=process.env.keyfetch_dealer ?
                <div className="form-container-inline btmdiv">
                  <div className="form-left">
                  
                  {selectedDealer!="" &&
                  <div className="form-label-group error">
                      <label>Dealer*</label>
                      <MyAuto 
                        value={selectedDealer} 
                        options={dealerList}  
                        sugChange={(e) => this.sugChange(e, 'dealer')} 
                        name="dealer"
                      />
                  </div>                    
                  }
                  <h3 className="sub-text mb-12">Is your car used for?</h3>
                  <div className="check-box-container">

                    <div className="form-check">
                      <MyCheckbox
                        name="vehicleFeature.isRideshare"
                        id="cbx11"
                        checkBoxChange={(e) => this.featureChanges('isRideshare', this, e)}
                        labelClass="check-container big round"
                        value={vehicleFeature.isRideshare}
                        label="RideShare"
                      />
                    </div>

                    <div className="form-check">
                      <MyCheckbox
                        name="vehicleFeature.isCommercial"
                        id="cbx12"
                        checkBoxChange={(e) => this.featureChanges('isCommercial', this, e)}
                        labelClass="check-container big round"
                        value={vehicleFeature.isCommercial}
                        label="Commercial"
                        title=""
                        spot="right"
                      />
                      <Popup
                        linkText={'<img class="minq" src='+quest+' />'}
                        popupHead={'Commercial'} 
                        popupMessage={`When selected, Coverage of Your Vehicle will be provided even though You are using Your Vehicle for a Commercial Use as defined herein. Other commercial uses that are explicitly excluded in “SECTION 4 - WHAT THIS SERVICE CONTRACT DOES NOT COVER” remain excluded and are not covered even with the selection of the Commercial Use Surcharge`}  
                        btnLabel={'Ok'} 
                      />
                    </div>

                  </div>
                  
                  <h3 className="sub-text mb-12 mt10">Would you like to add additional coverage?</h3>
                  <div className="check-box-container">

                    <div className="form-check">
                      <MyCheckbox
                        name="vehicleFeature.isEnhanchedTech"
                        id="cbx21"
                        checkBoxChange={(e) => this.featureChanges('isEnhanchedTech', this, e)}
                        labelClass="check-container big"
                        value={vehicleFeature.isEnhanchedTech}
                        label="Enhanched Tech"
                        title=""
                        spot="right"
                      />
                      <Popup
                        linkText={'<img class="minq" src='+quest+' />'}
                        popupHead={'Enhanched Tech'} 
                        popupMessage={`When selected, Coverage will be provided for the following parts: auto dimmers, autonomous cruise control systems, blind spot sensors, bluetooth systems, collision avoidance systems, driver information center, driver recognition systems, eye detection systems, hands free systems, heads up display systems, humidity sensors, infrared systems, lane departure systems, multimedia interface systems, navigation packages, proximity sensors, rear monitoring cameras, rest recommendation systems, seat heaters and coolers, self-parking systems, SOS systems, touch screen technology, vibration sensors, and voice recognition/command systems.`}  
                        btnLabel={'Ok'} 
                      />
                    </div>

                    <div className="form-check hideme">
                      <MyCheckbox
                        name="vehicleFeature.isCanadian"
                        id="cbx22"
                        checkBoxChange={(e) => this.featureChanges('isCanadian', this, e)}
                        labelClass="check-container big"
                        value={vehicleFeature.isCanadian}
                        label="Canadian"
                        title=""
                      />
                    </div>

                    <div className="form-check">
                      <MyCheckbox
                        name="vehicleFeature.isWPW"
                        id="cbx23"
                        checkBoxChange={(e) => this.featureChanges('isWPW', this, e)}
                        labelClass="check-container big"
                        value={vehicleFeature.isWPW}
                        label="Wait Period Waiver"
                        title=""
                        spot="right"
                      />
                      <Popup
                        linkText={'<img class="minq" src='+quest+' />'}
                        popupHead={'Wait Period Waiver'} 
                        popupMessage={`When selected, Provider will waive the thirty (30) day wait period between the Service Contract Purchase Date and Effective Date for coverage under ”SECTION 2 - WHAT THIS CONTRACT COVERS”  when Your Vehicle has been inspected by the Administrator within seventy-two (72) hours of Your purchase of this Service Contract and no pre-existing conditions are identified. Wait Period Waiver is subject to the Administrator's approval. If the wait period is waived, the Effective Date will be the date immediately subsequent to approval by the Administrator.`}  
                        btnLabel={'Ok'} 
                      />
                    </div>
                    <div className="form-check">
                      <MyCheckbox
                        name="vehicleFeature.isLiftKit"
                        id="cbx24"
                        checkBoxChange={(e) => this.featureChanges('isLiftKit', this, e)}
                        labelClass="check-container big"
                        value={vehicleFeature.isLiftKit}
                        label="LiftKit"
                        title=""
                        spot="right"
                      />
                      <Popup
                        linkText={'<img class="minq" src='+quest+' />'}
                        popupHead={'LiftKit'} 
                        popupMessage={`When selected, Coverage of Your Vehicle will be provided even though Your Vehicle has a body or suspension lift up to six inches (6”) and/or Your Vehicle has tires or wheels that are up to four inches (4”) larger in diameter than the original manufacturer equipment on the Service Contract Purchase Date. Payment of this Surcharge does not provide coverage for vehicles with over a six inch (6”) lift, or tires or wheels that are greater than four inches (4”) larger in diameter than the original manufacturer equipment, and such vehicles are explicitly excluded. Non-factory body and suspension parts are explicitly excluded. Payment of this Surcharge does not provide coverage for vehicles with oversized tires that do not match in diameter, and such vehicles are explicitly excluded. Suspension reductions and undersized wheels or tires are specifically excluded. Any modification that voids the original manufacturer warranty will also void coverage under the Surcharge.`}  
                        btnLabel={'Ok'} 
                      />
                    </div>

                  </div>
                  </div>
                
                  <div className="form-right">

                  

                  <h3 className="sub-text mb-12">Your vehicle has these features</h3>
                  <div className="check-box-container">
                    <div className="form-check">
                      <MyCheckbox
                        name="vehicleFeature.isAWD4x4"
                        id="cbx1"
                        labelClass="check-container big"
                        value={vehicleFeature.isAWD4x4}
                        label="AWD/4x4"
                        disabled={this.state.check.vin}
                        checkBoxChange={(e) => this.featureChanges('isAWD4x4', this, e)}
                        title="Your Vehicle is equipped with All wheel drive or four wheel drive"
                        spot="right"
                      />
                    </div>

                    <div className="form-check nwln">
                      <MyCheckbox
                        name="vehicleFeature.isTurboSuper"
                        id="cbx3"
                        labelClass="check-container big"
                        value={vehicleFeature.isTurboSuper}
                        label="Turbo Supercharger"
                        disabled={this.state.check.vin}
                        checkBoxChange={(e) => this.featureChanges('isTurboSuper', this, e)}
                        title="Your vehicle is equipped with a turbo charger or supercharger"
                        spot="right"
                      />
                    </div>
                      <div className="form-check hideme">
                      <MyCheckbox
                        name="vehicleFeature.isOneTon"
                        id="cbx4"
                        labelClass="check-container big"
                        value={vehicleFeature.isOneTon}
                        label="One Ton Vehicle"
                        disabled={this.state.check.vin}
                        checkBoxChange={(e) => this.featureChanges('isOneTon', this, e)}
                      />
                     
                    
                     </div>
                  {/* </div>

                  <div className="check-box-container linbrk"> */}

                  <div className="form-check">
                      <MyCheckbox
                        name="vehicleFeature.isDiesel"
                        id="cbx2"
                        labelClass="check-container big round"
                        value={vehicleFeature.isDiesel}
                        label="Diesel"
                        disabled={this.state.check.vin}
                        checkBoxChange={(e) => this.featureChanges('isDiesel', this, e)}
                        title="Your Vehicle is equipped with a diesel engine"
                        spot="right"
                      />
                    </div>
                    <div className="form-check">
                      <MyCheckbox
                        name="vehicleFeature.isHybrid"
                        id="cbx6"
                        labelClass="check-container big round"
                        value={vehicleFeature.isHybrid}
                        label="Hybrid"
                        disabled={this.state.check.vin}
                        checkBoxChange={(e) => this.featureChanges('isHybrid', this, e)}
                        title="Your vehicle is a hybrid"
                        spot="right"
                      />
                    </div>
                    <div className="form-check">
                      <MyCheckbox
                        name="vehicleFeature.isElectric"
                        id="cbx7"
                        labelClass="check-container big round"
                        value={vehicleFeature.isElectric}
                        label="Electric"
                        disabled={this.state.check.vin}
                        checkBoxChange={(e) => this.featureChanges('isElectric', this, e)}
                        title="Your vehicle is electric"
                        spot="right"
                      />
                    </div>

                    

                    <div className="form-container-button mt10">
                      {/* <div className="mb-32"></div> */}
                      <div className="error-txt">{infoError}</div>
                      <button type="submit" disabled={!canSubmit} className={!canSubmit ? 'btn disabled wizard-btn vehicle-btn' : 'btn wizard-btn vehicle-btn'}>
                        <ButtonLoader isLoading={false} label={'continue'} /> 
                      </button>
                    </div>
                  </div>

                  </div>
                </div>
                :
                <div className="form-container-button mt10">
                  <div className="error-txt">{infoError}</div>
                  <button type="submit" disabled={!canSubmit} className={!canSubmit ? 'btn disabled wizard-btn vehicle-btn' : 'btn wizard-btn vehicle-btn'}>
                  <ButtonLoader isLoading={false} label={'continue'} /> 
                  </button>
                </div>
                }
                {/* <Link to="/yourInfo" className="btn">Continue</Link> */}
              </Formsy>
              <div className="txtright">* Field is required</div>
              
            </div>
          </div>
          {global.dealertoken?'':
          <div className="vehicle-bottom-line bottom-line">
            <img src={bottomLine} alt="line" />
          </div>
          }
        </section>


      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageLoader: state.primary.pageLoader,
    step1Data: state.register.step1,
    step2response: state.register.step2response

  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    saveSecondStep: (formData) => dispatch(actionCreators.saveSecondStep(formData)),
    saveSecondStepResponse: (response) => dispatch(actionCreators.saveSecondStepResponse(response)),
    saveFirstStep: (formData) => dispatch(actionCreators.saveFirstStep(formData)),
    saveThirdStep: (formData) => dispatch(actionCreators.saveThirdStep(formData)),
    saveThirdStepResponse: (response) => dispatch(actionCreators.saveThirdStepResponse(response)),
    saveFourthStep: (formData) => dispatch(actionCreators.saveFourthStep(formData)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleInfoPage);